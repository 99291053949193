<script>
import { h, ref } from 'vue'
import thirdpartyTemplate from './components/thirdparty-template.vue'
import miniappMsgTemplateItem from './components/miniapp-msg-template-item.vue'
import { ElTabs, ElTabPane } from 'element-plus'
export default {
  setup () {
    const thirdpartyTemplateCom = ref(null)
    const miniappMsgTemplateItemCom = ref(null)
    const tabs = {
      activeName: 'thirdpartyTemplate',
      tabs: [
        { label: '小程序版本', name: 'thirdpartyTemplate', component: thirdpartyTemplate, ref: thirdpartyTemplateCom },
        { label: '订阅消息模板', name: 'miniappMsgTemplateItem', component: miniappMsgTemplateItem, ref: miniappMsgTemplateItemCom }
      ]
    }
    return {
      tabs
    }
  },
  render () {
    return h(
      ElTabs, {
        modelValue: this.tabs.activeName
      }, () => this.tabs.tabs.map(item => {
        return h(ElTabPane, {
          name: item.name,
          label: item.label
        }, () => [
          h(item.component, {
            ref: item.ref || ''
          })
        ])
      })
    )
  }
}
</script>
<style lang="scss" scoped>
</style>
