<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-select v-model="search.type" placeholder="请选择订阅消息模板项" size="small" @change="changeType()">
          <el-option v-for="item in selector.typeList" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
        <el-button size="small" type="primary" @click="add">新增</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>订阅消息模板项</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <el-form-item label="名称" prop="name" :rules="[
        {required:true, message: '名称',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.name" size="small" maxlength="16" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElSelect, ElOption, ElTableColumn, ElInput, ElDialog, ElForm, ElFormItem } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'

function table () {
  const search = reactive({
    type: 0
  })
  const selector = reactive({
    typeList: [{
      type: 0,
      name: '签到'
    }, {
      type: 1,
      name: '拼团成功通知'
    }, {
      type: 2,
      name: '拼团失败通知'
    }, {
      type: 3,
      name: '订单完成通知'
    }, {
      type: 4,
      name: '订单取消通知'
    }, {
      type: 5,
      name: '奖励领取通知'
    }]
  })
  /**
     * 表单信息
     */
  const table = ref(null)
  /**
     * 查询表单列表
     */
  const queryData = () => {
    return new Promise((resolve) => {
      axios('/manager/miniapp/msg/template/item/list', {
        type: search.type
      })
        .then(res => {
          table.value.judgePosition(res.miniappMsgTemplateItemList, res.miniappMsgTemplateItemList.length)
          resolve({
            list: res.miniappMsgTemplateItemList
          })
        }).catch(_ => { })
    })
  }

  onMounted(async () => {
    table.value.reload()
  })

  const changeType = () => {
    table.value.reload()
  }

  return {
    table,
    queryData,
    search,
    selector,
    changeType
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}

function operation (t, d) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增模板项'
    d.dialog.data = {
      id: '',
      name: ''
    }
  }

  /**
    * 修改模板
    */
  const modify = miniappMsgTemplateItem => {
    d.dialog.visible = true
    d.dialog.title = '修改模板项'
    d.dialog.data = {
      id: miniappMsgTemplateItem.id,
      name: miniappMsgTemplateItem.name
    }
  }

  const del = miniappMsgTemplateItem => {
    axios('/manager/miniapp/msg/template/item/del', {
      id: miniappMsgTemplateItem.id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }

  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }

  const submit = () => {
    axios('/manager/miniapp/msg/template/item/save', {
      ...d.dialog.data,
      type: t.search.type
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }

  return {
    add,
    del,
    modify,
    confirm
  }
}

export default {
  components: { ElButton, ElTableColumn, ElSelect, ElOption, ElInput, ElDialog, ElForm, ElFormItem },

  setup () {
    const t = table()
    const d = dialog()
    const o = operation(t, d)
    return {
      ...t,
      ...d,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
.drawer-button {
  padding: 10px 0;
}
</style>
