<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <el-button size="small" type="primary" @click="add">新增</el-button>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`模板列表(${totalCount}条)`}}</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="templateId" label="普通模板id"></el-table-column>
        <el-table-column prop="version" label="版本"></el-table-column>
        <el-table-column prop="desc" label="说明"></el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <el-form-item label="普通模板id" prop="templateId" :rules="[
        {required:true, message: '普通模板id',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.templateId" size="small" maxlength="16" clearable></el-input>
      </el-form-item>
      <el-form-item label="版本号" prop="version" :rules="[
        {required:true, message: '版本号',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.version" size="small" maxlength="16" clearable></el-input>
      </el-form-item>
      <el-form-item label="说明" prop="desc" :rules="[
        {required:true, message: '说明不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.desc" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElTableColumn, ElInput, ElDialog, ElForm, ElFormItem } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'

function table () {
  /**
     * 表单信息
     */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/manager/thirdparty/template/list', {
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.thirdpartyTemplateList, res.totalCount)
          resolve({
            list: res.thirdpartyTemplateList,
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }

  onMounted(async () => {
    table.value.reload()
  })

  return {
    table,
    queryData,
    totalCount
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}

function operation (t, d) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增模板'
    d.dialog.data = {
      id: '',
      templateId: '',
      version: '',
      desc: ''
    }
  }

  /**
    * 修改模板
    */
  const modify = thirdpartyTemplate => {
    d.dialog.visible = true
    d.dialog.title = '修改小程序版本'
    d.dialog.data = {
      id: thirdpartyTemplate.id,
      templateId: thirdpartyTemplate.templateId,
      version: thirdpartyTemplate.version,
      desc: thirdpartyTemplate.desc
    }
  }

  const del = thirdpartyTemplate => {
    axios('/manager/thirdparty/template/del', {
      thirdpartyTemplateId: thirdpartyTemplate.id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }

  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }

  const submit = () => {
    axios('/manager/thirdparty/template/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }

  return {
    add,
    del,
    modify,
    confirm
  }
}

export default {
  components: { ElButton, ElTableColumn, ElInput, ElDialog, ElForm, ElFormItem },

  setup () {
    const t = table()
    const d = dialog()
    const o = operation(t, d)
    return {
      ...t,
      ...d,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
.drawer-button {
  padding: 10px 0;
}
</style>
